import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import Config from '../../../helpers/Config';
import SiteBreadcrumb from '../../components/layout/SiteBreadcrumb';
import { Media, Row, Col, Container } from "reactstrap";
import aboutImg from '../../../resources/custom/images/about-us.jpg';
import CustomerTestimonial from '../../components/shared/CustomerTestimonial';
import { GetLocalizationControlsJsonDataForScreen, replaceLoclizationLabel } from '../../../helpers/CommonHelper';
import rootAction from '../../../stateManagment/actions/rootAction';
import GlobalEnums from '../../../helpers/GlobalEnums';
import { LOADER_DURATION } from '../../../helpers/Constants';
import { useSelector, useDispatch } from 'react-redux';

const About = () => {
  const [siteTitle, setSiteTitle] = useState(Config['SITE_TTILE']);
  const [LocalizationLabelsArray, setLocalizationLabelsArray] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    // declare the data fetching function
    const dataOperationInUseEffect = async () => {

      //-- Get website localization data
      let arryRespLocalization = await GetLocalizationControlsJsonDataForScreen(GlobalEnums.Entities["About"], null);
      if (arryRespLocalization != null && arryRespLocalization != undefined && arryRespLocalization.length > 0) {
        await setLocalizationLabelsArray(arryRespLocalization);
      }

    }
    //--start loader
    dispatch(rootAction.commonAction.setLoading(true));

    // call the function
    dataOperationInUseEffect().catch(console.error);

    //--stop loader
    setTimeout(() => {
      dispatch(rootAction.commonAction.setLoading(false));
    }, LOADER_DURATION);
  }, [])


  return (
    <>


      <Helmet>
        <title>{siteTitle} - About Us</title>
        <meta name="description" content={siteTitle + " - About us page"} />
        <meta name="keywords" content="About us"></meta>
      </Helmet>

      {/*<SiteBreadcrumb title="Contact Us" parent="Home" /> */}
      <SiteBreadcrumb title={LocalizationLabelsArray.length > 0 ?
        replaceLoclizationLabel(LocalizationLabelsArray, "About Us", "lbl_about_title")
        :
        "About Us"} parent={LocalizationLabelsArray.length > 0 ?
          replaceLoclizationLabel(LocalizationLabelsArray, "Home", "lbl_cont_title_home")
          :
          "Home"} />


      <section className="about-page section-big-py-space">
        <div className="container">
          <Row>
            <Col lg="6">
              <div className="banner-section">
                <Media src={aboutImg} className="img-fluid w-100" alt="" />
              </div>
            </Col>
            <Col lg="6">
              <h4> {LocalizationLabelsArray.length > 0 ?
                replaceLoclizationLabel(LocalizationLabelsArray, "Welcome", "lbl_about_welcome_title")
                :
                "About Us"}</h4>
              <p className="mb-2">
                {LocalizationLabelsArray.length > 0 ?
                  replaceLoclizationLabel(LocalizationLabelsArray, "Welcome", "lbl_about_welcome")
                  :
                  "Welcome to <b>KinkUz Shop</b> , your one-stop-shop for all your online shopping needs!"}

              </p>
              <p className="mb-2">
                {LocalizationLabelsArray.length > 0 ?
                  replaceLoclizationLabel(LocalizationLabelsArray, "About Us", "lbl_about_welcome2")
                  :
                  " Our website is built using the latest technologies such as ASP.NET MVC .NET 6 and ReactJS to provide you with a seamless and intuitive shopping experience." +
                  " We offer a wide range of products from various categories, including electronics, fashion, beauty, home and living, sports and fitness, and much more. "
                }
              </p>

            </Col>
          </Row>
        </div>
      </section>

      <CustomerTestimonial />

    </>
  );

}

export default About;
